<template>
  <div>
    <section class="page-top">
      <transition :name="exclusiontext">
        <h4>Scroll Down</h4>
      </transition>
    </section>

    <section id="portfolio-section">

    <section id="portfolio-main">

    <div class="portfolio-piece">
        <div class="scroll-header">
            <div class="inf-scroll">
                <div>
                    <h1>Good Time Charlies <span>Case Study</span></h1>
                    <h1>Good Time Charlies <span>Case Study</span></h1>
                </div>
            </div>
        </div>

        <div class="portfolio-cont">
            <div class="p-content-row-2">
                <div>
                    
                </div>
                <div>
                    <p>Charlie's enjoys a long history of serving customers in San Antonio, TX, but the restaurant's look was in need of an update. The first step was to identify the brand culture and 
                        establish the story that needed to be told.</p>
                </div>
            </div>

            <div class="p-content-row-2">
                <div>
                    <figure class="medium-img">
                        <img alt="good time charlies location sign" src="../assets/images/portfolio/casestudy/location_sign.jpg">
                    </figure>
                </div>
                <div>
                    <figure class="medium-img">
                        <img alt="good time charlies facade" src="../assets/images/portfolio/casestudy/location_outdoors.jpg">
                    </figure>
                    <figure class="medium-img">
                        <img alt="good time charlies menu" src="../assets/images/portfolio/casestudy/location_menu.jpg">
                    </figure>
                </div>
            </div>
        </div>


        <div class="scroll-header">
            <div class="inf-scroll">
                <div>
                    <h1>Sketches <span>Sketches </span>Sketches <span>Sketches </span></h1>
                    <h1>Sketches <span>Sketches </span>Sketches <span>Sketches </span></h1>
                </div>
            </div>
        </div>

        <div class="portfolio-cont">

            <div class="p-content-row-2">
                <div>
                    
                </div>
                <div>
                    <p>The first step was to draft sketches of potential ideas. Many sketches were drawn exploring a variety of possibilities.</p>
                </div>
            </div>

            <div class="p-content-row-2">
                <div>
                    <figure class="medium-img">
                        <img alt="sketches for good time charlies" src="../assets/images/portfolio/casestudy/sketch_full.jpg">
                    </figure>
                </div>
                <div>
                    <figure class="medium-img">
                        <img alt="sketches for good time charlies" src="../assets/images/portfolio/casestudy/sketch_digital.jpg">
                    </figure>
                </div>
            </div>
        </div>


        <div class="scroll-header">
            <div class="inf-scroll">
                <div>
                    <h1>Exploration <span>Exploration</span></h1>
                    <h1>Exploration <span>Exploration</span></h1>
                </div>
            </div>
        </div>

        <div class="portfolio-cont">
            <div class="p-content-row-2">
                <div>
                    
                </div>
                <div>
                    <p>With a more solid idea of the concept, the next step was to explore additional possibilities. During this time, an identity formed alongside the logo, which would include 
                        typography and colors.</p>
                </div>
            </div>

            <div class="p-content-row-2">
                <div>
                    <figure class="medium-img">
                        <img alt="good time charlies logo work" src="../assets/images/portfolio/casestudy/working_typeface.jpg">
                    </figure>
                </div>
                <div>
                    <figure class="medium-img">
                        <img alt="good time charlies logo development" src="../assets/images/portfolio/casestudy/working_variations.jpg">
                    </figure>
                    <figure class="medium-img">
                        <img alt="good time charlies brand elements" src="../assets/images/portfolio/casestudy/working_identity.jpg">
                    </figure>
                </div>
            </div>
        </div>


        <div class="scroll-header">
            <div class="inf-scroll">
                <div>
                    <h1>Brand Guide <span>Brand Guide</span></h1>
                    <h1>Brand Guide <span>Brand Guide</span></h1>
                </div>
            </div>
        </div>

        <div class="portfolio-cont">
            <div class="p-content-row-2">
                <div>
                    
                </div>
                <div>
                    <p>To finalize the design, a brand guide was created to illustrate the concept in it's entirety. This book would include many rules and guidelines for the new Charlie's brand.</p>
                </div>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="good time charlies brand guide pages" src="../assets/images/portfolio/casestudy/brandguide_story.jpg">
                </figure>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="good time charlies brand guide pages" src="../assets/images/portfolio/casestudy/brandguide_nomenclature.jpg">
                </figure>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="good time charlies brand guide pages" src="../assets/images/portfolio/casestudy/brandguide_typecolors.jpg">
                </figure>
            </div>
        </div>

    </div>
</section>
</section>
<footer-info class="footer-back" />
</div>
</template>

<script>

export default {
    name: "CaseStudy",

}
</script>

<style lang="scss">

</style>